<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";

/**
 * User list component
 */
export default {
  components: { Layout, PageHeader },
  page: {
    title: "Notifications",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Notifications",
      items: [
        {
          text: "Notifications",
        },
        {
          text: "Notifications",
          active: true,
        },
      ],
      notifications: [],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      //return this.userList.length;
      return 1;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        this.getNotifications();
      }
    });
  },
  methods: {
    dateFromTimestamp(timestamp) {
      var date = new Date(timestamp);
      return (
        date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " " + date.getHours()+":"+date.getMinutes()
      );
    },
    daysBetweenTimestamps(date) {
      var now = new Date().getTime();
      // The number of milliseconds in one day
      const ONE_DAY = 1000 * 60 * 60 * 24;

      // Calculate the difference in milliseconds
      const differenceMs = date - now;
      // Convert back to days and return
      var diffJours = Math.round(differenceMs / ONE_DAY);
      return diffJours;
    },

    async getNotifications() {
      this.loadingValidate = true;
      console.log(Date.getTime)
      var db = firebase.firestore();
      var requete = db.collection("notifications").orderBy("date", "desc");
      requete = requete.where("date", ">=", new Date().getTime() - 259200000);

      if (!JSON.parse(this.isAdmin)) {
        await firebase
          .firestore()
          .collection("users")
          .doc(firebase.auth().currentUser.uid)
          .get()
          .then((coach) => {
            requete = requete.where("coach", "==", coach.ref);
          });
      }

      requete.onSnapshot(async (notifications) => {
        console.log(JSON.stringify(notifications.docs.length))

        this.notifications = [];
        notifications.forEach(async (notification) => {
          var data = notification.data();
          var user = data.user;
          var coach = data.coach;
          var userData = await user.get();
          var coachData = await coach.get();
          var userId = user.id;
          console.log("user data:" + userId);
          this.notifications.push({
            id: notification.id,
            data: notification.data(),
            user: userData.data(),
            userId: userId,
            coach: coachData.data(),
          });
          this.loadingValidate = false;
        });
      });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h2 class="my-3">Notifications 72h</h2>

            <table class="table mb-4">
              <thead>
                <tr>
                  <th scope="col" width="50px">#</th>
                  <th scope="col">User</th>
                  <th scope="col">Date</th>
                  <th scope="col">Coach</th>
                  <th scope="col">Type</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="notification in notifications"
                  :key="notification.id + 'notification'"
                >
                  <td class="align-middle">
                    <img
                      v-if="notification.user.photo_profil"
                      :src="notification.user.photo_profil"
                      alt
                      class="avatar-xs rounded-circle me-2"
                    />
                    <div
                      v-if="!notification.user.photo_profil"
                      class="avatar-xs d-inline-block me-2"
                    >
                      <div
                        class="
                          avatar-title
                          bg-soft-primary
                          rounded-circle
                          text-primary
                        "
                      >
                        <i class="mdi mdi-account-circle m-0"></i>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle">
                    <router-link
                      :to="{
                        name: 'Profile',
                        params: { id: notification.userId },
                      }"
                    >
                      {{ notification.user.first_name }}
                      {{ notification.user.last_name }}
                    </router-link>
                  </td>
                  <td class="align-middle">{{ dateFromTimestamp(notification.data.date) }}</td>
                  <td>
                    {{ notification.coach.first_name }}
                    {{ notification.coach.last_name }}
                  </td>
                  <td class="align-middle">
                    <b>{{ notification.data.type }}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
